import React, { useState } from "react"
import styled, { keyframes } from "styled-components";
import { graphql } from "gatsby";
import {StaticImage, GatsbyImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {SectionHero, Section, SectionDescription, SectionPageTitle, SectionImageBkg, SectionTitle, ExploreMore} from "../components/Section"
import {LoadingIcon, PhoneIcon} from "../components/Icons"
import DefaultButton from "../components/Button/DefaultButton"
import PrimaryButton from "../components/Button/PrimaryButton"
import CategoryLandingProductCardList from "../components/ProductCardList/CategoryLandingProductCardList"
import StructuresTypes from "../components/StructuresTypes"
import ColorPicker from "../components/ColorPicker"
import {FeatureGrid, FeatureGridLeft, FeatureGridRight, FeatureGridInner, FeatureGridItem, Mascot, FeatureTitle, ListFeatures, ListFeatureItem} from "../components/Features"

const Spin = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

const SectionBanner = styled(SectionHero)`
  position:relative;
  height: 100%; 

`
const HeroBanner = styled.div`	
    position: relative;
    height:100%;
    @media(max-width:991.98px){
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }

    @media(min-width:992px){
        height: calc(100vh - 64px);
    }
    @media(min-width:1200px){
        height: calc(100vh - 80px);
    }
    .gatsby-image-wrapper {
        width:100%;
        height:100%;
        opacity:0.05;
        @media(min-width:992px){
            opacity:0.1;
        }
    }  
`

const HeroCaption = styled.div`	
    @media(min-width:992px){
        position:absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        .container{
            height:100%;
        }
    }
`

const HeroCaptionGrid = styled.div`
	position: relative;
  display:flex;
  flex-direction:column-reverse;
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
  }
`
const HeroCaptionLeft = styled.div`
    position: relative;
    display:flex;
    flex-wrap:wrap;
    align-items:center;

    padding:0 15px;
    @media (min-width: 992px) {
        height: calc(100vh - 64px);
        padding:0 30px;
    }
    @media (min-width: 1200px) {
        height: calc(100vh - 84px);
    }
    @media (min-width:1600px) {
        padding:0 30px;
    }
    @media (min-width: 1650px) {
        padding: 0 30px 0 calc(50vw - 810px);  
    }
    
`
const HeroCaptionInnerLeft = styled.div`
    margin:40px 0;
    @media (min-width: 992px) {
        margin:0;
    }
`
const SectionDescriptions = styled(SectionDescription)`
  /* strong, b, mark{
    background:#1383C1;
		color:#fff;
		padding: 3px 10px;
  } */
  strong{
    font-weight: 500 !important;
  }
  p{  
    font-size:18px;
    line-height:30px; 
    @media(min-width:1600px){
      font-size:22px;
      line-height:40px; 
    }
  }
`
const HeroCaptionRight = styled.div`
    position: relative;
    padding:0 15px;
    border-bottom:1px solid #ccc;
    @media (min-width: 992px) {
        height: calc(100vh - 64px);
        padding:0 30px;
        border-bottom:0;
    }
    @media (min-width: 1200px) {
        height: calc(100vh - 84px);
    }
    @media (min-width:1600px) {
        padding:0 30px;
    }
    @media (min-width: 1650px) {
        padding: 0 calc(50vw - 810px) 0 30px;  
    }
`
const HeroFigure = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    position:relative;
    z-index: 1;
    @media (min-width: 992px) {
        position: absolute;
        right:0;
    }
    > .gatsby-image-wrapper{
        filter: drop-shadow(0 3px 25px rgba(0, 0, 0, 0.36))
    }

`
const HeroBackground = styled.div`
    position: absolute;
    z-index: 0;
	width:100%;
	height:100%;
	top:0;    
    left: 0;
    &:before{
        position:absolute;        
        content:'';
        width:100%;
		height:100%;
        background-color:rgba(255,255,255,0.4);
        clip-path: ellipse(calc(80% + 70px) calc(100% + 70px) at 100% 50%);
    }
	> .gatsby-image-wrapper{
		width:100%;
		height:100%;
        clip-path: ellipse(80% 100% at 100% 50%);
	}
`


const SectionFeature = styled(Section)`
  border-top:1px solid #ccc;
  border-bottom:1px solid #ccc;
  padding:40px 0;
  @media(min-width:992px){
    padding:60px 0;
  }
  @media(min-width:1600px){
    padding:90px 0;
  }
`

const SectionColor = styled(Section)`
  border-top:1px solid #ccc;
  .color-palette{
    padding-bottom:0;
  }
`
const SectionImageBkgs = styled(SectionImageBkg)`
  display:none;
  @media(min-width:992px){
    display:block;
  }
`

const ExploreMores = styled(ExploreMore)`
  .btn {
    font-weight:900;
    > .icon{
      animation: ${Spin} 3s linear infinite;
    }
  }
`

const SectionTitleCustom = styled(SectionTitle)`
  text-align:center;
  margin-bottom:60px;
  @media(min-width:1200px){
    margin-bottom:100px;
  }
`

const CategoryLanding = ({ data, location }) => {
  const pageData = data.contentfulProductCategory;
  const productData = data.allContentfulProduct.edges;
  const [showLimit, setShowLimit] = useState(12);
  // const [isModalVisible, setIsModalVisible] = useState(false)

  // const HandleModalOpen = () => {
  //   setIsModalVisible(true)
  //   setShowLimit(12 + 6);
  // }
  // const HandleModalClose = () => {
  //   setIsModalVisible(false)
  // }
  // useEffect(() => {
  //     if (isModalVisible) {
  //         document.querySelector("body").classList.add("modal-open")
  //     } else {
  //         document.querySelector("body").classList.remove("modal-open")
  //     }
  // }, [isModalVisible, setIsModalVisible])
  return(
    <Layout location={location} noCta>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <SectionBanner className="section-hero">
          <HeroBanner><StaticImage src="../images/inside-center-carport.jpg" alt="inside-center-carport" /></HeroBanner>
          <HeroCaption>
              <HeroCaptionGrid>
                  <HeroCaptionLeft>
                      <HeroCaptionInnerLeft>
                          <SectionPageTitle className="h3" mb="0" fontWeight="700">{pageData.name}</SectionPageTitle>
                          <SectionDescriptions fontStyle="italic" mb="15px">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: pageData.description.childMarkdownRemark.html,
                              }}
                            />
                          </SectionDescriptions>
                          <a href="tel:3869610006" aria-label="Phone"><PrimaryButton icon={<PhoneIcon />} text="386-961-0006" animate="ripple-white" /></a>

                      </HeroCaptionInnerLeft>
                  </HeroCaptionLeft>
                  <HeroCaptionRight>
                      <HeroFigure>
                        <GatsbyImage image={pageData.image.gatsbyImageData} alt={pageData.name} />
                      </HeroFigure>
                      <HeroBackground>
                        <StaticImage src="../images/product-bg.jpg" alt="Product Background" />
                      </HeroBackground>
                  </HeroCaptionRight>
              </HeroCaptionGrid>
          </HeroCaption>
      </SectionBanner>
  
      <Section pt="120px" pb="120px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#fff" className="section-category">
          <div className="container">
              <SectionTitle textAlign="center" mb="30px">Explore {pageData.name}</SectionTitle>
              { productData.length > 1 ? (
                <CategoryLandingProductCardList data={productData} showLimit={showLimit} />
              ) : (
                <SectionDescription textAlign="center">There is no product for this category</SectionDescription>
              )}

              {productData.length > showLimit && (
                <ExploreMores>
                  <button type="button" aria-label="button" onClick={() => setShowLimit(showLimit + 6)}><DefaultButton primary text="Load More buildings…" animate="ripple-black" /></button>                            
                </ExploreMores>
              )}
          </div>
      </Section>
  
      <SectionColor pt="80px" pb="0" xpt="60px" mpt="60px"  bgColor="#fff" >
        <div className="container"><SectionTitleCustom>Available Color Options</SectionTitleCustom></div>
        <ColorPicker />
      </SectionColor>
  
      <SectionFeature pt="0" pb="0" bgColor="#fff" className="section-features">	
          <SectionImageBkgs width="30%" opacity="0.05"><StaticImage src="../images/inside-circle-carport.png" alt="inside-circle-carport" /></SectionImageBkgs>
          <div className="container">
          <FeatureGrid>
              <FeatureGridLeft>
              <Mascot><StaticImage src="../images/mascot-watermark.png" alt="mascot-watermark" /></Mascot>
              </FeatureGridLeft>
              <FeatureGridRight>
              <FeatureGridInner>
                  <FeatureGridItem>
                  <FeatureTitle>STANDARD FEATURES:</FeatureTitle>
                  <ListFeatures>
                    {
                      pageData.features.map((item, i) => (
                        <ListFeatureItem key={i}><mark>{item.content}</mark></ListFeatureItem>
                      ))
                    }
                  </ListFeatures>
                  </FeatureGridItem>
                  <FeatureGridItem>
                  <StructuresTypes />
                  </FeatureGridItem>
              </FeatureGridInner>
              </FeatureGridRight>
          </FeatureGrid>
          </div>
      </SectionFeature>     
    </Layout>
  )
}

export default CategoryLanding

export const pageQuery = graphql`
  query CategoryLandingQuery($id: String!, $name: String!) {
    contentfulProductCategory(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      name
      image {
        gatsbyImageData(quality: 100)
      }
      features {
        content
      }
    }
    allContentfulProduct(sort: {fields: skuNumber}, filter: {category: {name: {eq: $name}}}) {
      edges {
        node {
          sku
          cardImage {
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
  }
`
